@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');

$FancyFont: 'Hind Madurai', sans-serif;
$regFont: "Roboto Slab', serif;";
$scriptFont: 'Bad Script', cursive;

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    margin-bottom:-10px;
    // border:1px solid red;
}

body{
    margin: 0px;
    padding: 0px;
    overflow-x: hidden!important;
}

#mainBg{
    margin:0px;
    padding:0px;
    height:100vh;
    width:100vw;
    background-image: linear-gradient(#343A40 , #495057);
    // background-color: #343A40;
    font-family: $FancyFont;
    color:#F8F9FA;
    text-align: center;
}

#SkillBox{
    margin:0px;
    padding:0px;
    width:100vw;
    background-image: linear-gradient(#495057 , #6C757D);
    // background-color: #495057;
    font-family: $regFont;
    color:#F8F9FA;
    text-align: center;
    padding-bottom:5vh;
}

#catie{
    animation: glow 1s infinite alternate;
}

.redOpt{
    border-radius:100%;
    width:150px;
    height:150px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 25px;
    transition: 0.2s ease-in-out;
}

#redOptEx{
  content: 'ADD';
}

.redOpt:hover{
    transform:scale(1.1)
}

#ballHolderaAYEOO a{
    color:white;
    text-decoration: none;
}

@keyframes glow {
    from {
      box-shadow: 0 0 10px -10px #212529;
    }
    to {
      box-shadow: 0 0 10px 10px #212529;
    }
  }

.ArrowContainer {
    position: relative;
    width: 24px;
    height: 24px;
    text-align: center;
    top:5vh;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }
  
  .chevron:first-child {
    animation: move 3s ease-out 1s infinite;
  }
  
  .chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }
  
  .chevron:before,
  .chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
  }
  
  .chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }
  
  .chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }
  
  @keyframes move {
    25% {
      opacity: 1;
  
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }
  
  @keyframes pulse {
    to {
      opacity: 1;
    }
  }

  @keyframes green{
    0% {
      color:white;
    }

    50%{
      color:green
    }

    100%{
      color:white
    }
  }

  #greenAnimate{
    animation: green 3s ease-out 2s infinite;
  }

  #quoteBox{
    margin:0px;
    padding:0px;
    width:100vw;
    background-image: linear-gradient(#6C757D , #686D76);
    // background-color: #6C757D;
    font-family: $regFont;
    color:#F8F9FA;
    text-align: center;
    padding-bottom:5vh;
  }

  .quote{
      font-family: $scriptFont;
      margin:30px;
  }

  .quoteAuth{
      font-family: $FancyFont;
  }
  
  #footer{
      background-color:black;
      margin:0px;
      padding:0px;
      width:100vw;
      font-family: $regFont;
      color:#F8F9FA;
      text-align: center;
  }

  #faqBox{
    margin:0px;
    padding:0px;
    width:100vw;
    background-color: #686D76;
    font-family: $regFont;
    color:white;
    text-align: center;
    padding-bottom:5vh;
  }